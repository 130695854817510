<!--
 * @Author: chenlin 891635769@qq.com
 * @Date: 2022-08-04 18:26:03
 * @LastEditors: chenlin 891635769@qq.com
 * @LastEditTime: 2022-08-10 16:56:33
 * @FilePath: \_web_portal\src\views\onlineLearning\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="main pageMain">
    <Title></Title>

    <div class="box">
      <!-- <el-progress :text-inside="true" :stroke-width="16" :percentage="70"></el-progress> -->
      <div class="progress">
        <div style="margin-bottom: 10px">
          完成度{{ progressInfo >= 100 ? 100 : progressInfo }}%
        </div>
        <el-progress
          :percentage="progressInfo >= 100 ? 100 : progressInfo"
          :stroke-width="9"
          :show-text="false"
        ></el-progress>
      </div>
      <el-row>
        <el-col :span="8">
          <div class="titleList">
            <div class="mainTitle">目录</div>
            <el-collapse accordion>
              <el-collapse-item v-for="item in dataList" :key="item.id">
                <template slot="title">
                  <!-- <i class="header-icon el-icon-info"></i> -->
                  {{ item.title }}
                </template>
                <div
                  class="z_title"
                  v-for="s in item.data"
                  :key="s.id"
                  @click="toCourse(s)"
                >
                  <img
                    v-if="s.courseItemType === '2'"
                    src="@/assets/course/mini_video.png"
                    class="titleImg"
                  />
                  <img
                    v-else-if="s.courseItemType === '3'"
                    src="@/assets/course/mini_word.png"
                    class="titleImg"
                  />
                  <img
                    v-else-if="s.courseItemType === '4'"
                    src="@/assets/course/mini_pdf.png"
                    class="titleImg"
                  />
                  <img
                    v-else
                    src="@/assets/course/mini_exam.png"
                    class="titleImg"
                  />
                  <span>{{ s.title }}</span>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </el-col>
        <el-col :span="16">
          <div class="content">
            <div class="content_title_box">
              <div class="content_title">{{ courseItem.title }}</div>
              <div class="content_type">
                <i class="el-icon-menu"></i>
                {{ courseItemType(courseItem.courseItemType) }}
                <span v-if="courseItem.courseItemType === '4'"> <a :href="pdf.url" target="_blank" rel="noopener noreferrer">点击下载</a></span>
              </div>
            </div>

            <vueMiniPlayer
              v-show="courseItem.courseItemType === '2'"
              ref="vueMiniPlayer"
              class="video_box"
              :video="video"
              :mutex="true"
              :key="courseItem.id + 'video'"
            />
            <div
              v-show="courseItem.courseItemType === '3'"
              ref="file"
              class="word_box"
            ></div>
            <!-- <iframe
              v-if="courseItem.courseItemType === '4'"
              width="100%"
              class="iframe_box"
              :src="pdf.url"
              frameborder="0"
              :key="courseItem.id + 'pdf'"
            ></iframe> -->
            <div class="canvas-box" v-if="courseItem.courseItemType === '4'">
              <canvas
              v-for="page in pages"
              :id="'the-canvas' + page"
              :key="page"
            ></canvas>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <Footer style="margin-top: 100px"></Footer>
  </div>
</template>

<script>
import PDFJS from "pdfjs-dist";
import pdfjsLib from "pdfjs-dist";
import Title from "@/components/hometitle/hometitle.vue";
import Footer from "@/components/foot/foot.vue";
import {
  jcCourseDetail,
  jcCourseItemPage,
  jcCourseItemLogRead,
  jcCourseItemLogGetPlan,
} from "@/api/course/course";
import axios from "axios";
const docx = require("docx-preview");
window.JSZip = require("jszip");
export default {
  name: "",
  data() {
    return {
      pages: 0,
      dataList: [],
      progressInfo: 0,
      query: this.$route.query,
      course: {},
      video: {
        url: "https://api.dogecloud.com/player/get.mp4?vcode=5ac682e6f8231991&userId=17&ext=.mp4",
        /*             cover: 'https://i.loli.net/2019/06/06/5cf8c5d9c57b510947.png', */
        muted: false,
        loop: false,
        preload: "auto",
        poster: "",
        volume: 1,
        autoplay: false,
      },
      pdf: { url: "" },
      courseItem: {},
      dict: [],
      js_course_item_type: [],
    };
  },
  watch: {},
  props: {},
  components: {
    Title,
    Footer,
  },
  computed: {},
  created() {
    console.log(this.query.id);

    this.dict = JSON.parse(localStorage.getItem("dict"));

    if (this.dict) {
      this.js_course_item_type = this.dict.filter(
        (i) => i.code == "js_course_item_type"
      )[0].children;
    }
    console.log(this.js_course_item_type);

    jcCourseDetail({ id: this.query.id }).then((res) => {
      console.log("课程", res);
      if (res.success) {
        this.course = res.data;
      }
    });

    jcCourseItemPage({
      courseId: this.query.id,
      pageNo: 1,
      pageSize: 500,
    }).then((res) => {
      console.log("课程大纲", res);
      if (res.success) {
        var data = res.data.rows;
        var detailTree = data.filter((e) => e.pid === null);
        detailTree.forEach((d) => {
          d.data = data.filter((e) => e.pid === d.id);
        });
        console.log(detailTree);
        this.dataList = detailTree;
        if (detailTree[0].data[0]) {
          this.courseItem = detailTree[0].data[0];
          if (
            this.courseItem.courseItemType === "2" ||
            this.courseItem.courseItemType === "3"
          ) {
            this.toCourse(this.courseItem);
          }
        }
        jcCourseItemLogGetPlan({
          courseId: this.$route.query.id,
          jobUserId: localStorage.getItem("userId"),
        }).then((info) => {
          this.progressInfo = info.data;
        });
      }
    });
  },
  mounted() {
    this.initThePDFJSLIB();
  },
  methods: {
    courseItemType(code) {
      var obj = this.js_course_item_type.find((e) => e.code === code);
      if (obj) {
        return obj.name;
      } else {
        return "";
      }
    },
    urltofile(url) {
      return new Promise((resolve, reject) => {
        var blob = null;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.onload = () => {
          blob = xhr.response;
          resolve(blob);
        };

        xhr.onerror = (e) => {
          reject(e);
        };
        xhr.send();
      });
    },
    toCourse(courseItem) {
      this.pdf.url = ''
      console.log(courseItem);
      this.courseItem = courseItem;

      if (this.courseItem.courseItemType === "2") {
        // 视频课
        this.video.url = courseItem.file;
      } else if (this.courseItem.courseItemType === "3") {
        // word课
        this.$nextTick(() => {
          docx.renderAsync(this.urltofile(courseItem.file), this.$refs.file);
        });
      } else if (this.courseItem.courseItemType === "4") {
        // pdf课
        this.pdf.url = courseItem.file;
        this._loadFile(this.pdf.url);
      } else {
        // window.open("/examinationView?id="+courseItem.file, '_blank')
        this.$router.push({
          path: "/examinationView",
          query: {
            id: courseItem.file,
          },
        });
      }

      setTimeout(() => {
        this.jcCourseItemLog(courseItem);
      }, 500);
    },
    jcCourseItemLog(courseItem) {
      let obj = {
        courseId: this.query.id,
        jobUserId: localStorage.getItem("userId"),
        courseItemId: courseItem.id,
      };
      jcCourseItemLogRead(obj).then((res) => {
        console.log(res, "信息");
      });
    },
    initThePDFJSLIB() {
      pdfjsLib.GlobalWorkerOptions.workerSrc = "pdfjs-dist/build/pdf.worker.js";
    },
    _renderPage(num) {
      this.pdfDoc.getPage(num).then((page) => {
        let canvas = document.getElementById("the-canvas" + num);
        let ctx = canvas.getContext("2d");
        let dpr = window.devicePixelRatio || 1;
        let bsr =
          ctx.webkitBackingStorePixelRatio ||
          ctx.mozBackingStorePixelRatio ||
          ctx.msBackingStorePixelRatio ||
          ctx.oBackingStorePixelRatio ||
          ctx.backingStorePixelRatio ||
          1;
        let ratio = dpr / bsr;
        let viewport = page.getViewport(
          screen.availWidth / page.getViewport(1).width
        );
        canvas.width = viewport.width * ratio;
        canvas.height = viewport.height * ratio;
        canvas.style.width = viewport.width + "px";
        canvas.style.height = viewport.height + "px";
        ctx.setTransform(ratio, 0, 0, ratio, 0, 0);
        let renderContext = {
          canvasContext: ctx,
          viewport: viewport,
        };
        page.render(renderContext);
        if (this.pages > num) {
          this._renderPage(num + 1);
        }
      });
    },
    _loadFile(url) {
      PDFJS.getDocument(url).then((pdf) => {
        this.pdfDoc = pdf;
        this.pages = this.pdfDoc.numPages;
        this.$nextTick(() => {
          this._renderPage(1);
        });
      });
    },
  },
  filters: {},
};
</script>

<style scoped lang="scss">
.canvas-box{
  width: 780px !important;
  height: 540px !important;
  overflow-y: auto !important;
}
canvas {
  width: 820px !important;
  height: 1260px !important;
  display: block;
  border-bottom: 1px solid black;
}
.progress {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  color: #029aff;
  padding: 20px;
}
.box {
  // border: 1px solid red;
  // top: 65px;
  width: 1200px;
  margin: 0 auto;
  margin-top: 120px;
  border-radius: 4px;
  background-color: white;
  min-height: 600px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
}

.titleList {
  border: 1px solid #ccc;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
  margin: 20px;
  padding: 20px;
  min-height: 600px;
}
.mainTitle {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
}

.titleImg {
  width: 20px;
  height: 20px;
  opacity: 1;
  position: relative;
  top: 5px;
}
.z_title {
  margin: 10px;
  margin-bottom: -5px;
  line-height: 20px;
  cursor: pointer;
  span {
    margin: 10px;
    cursor: pointer;
  }
}

.content {
  margin: 20px;
  /*   padding: 20px; */
}

.content_title_box {
  background-color: #eef;
  min-height: 80px;
  margin-bottom: 20px;
  .content_title {
    margin: 20px;
    margin-bottom: 0px;
    font-weight: 800;
    font-size: 18px;
    min-height: 40px;
    line-height: 40px;
  }
  .content_type {
    margin-left: 20px;
    font-size: 16px;
    min-height: 40px;
    line-height: 40px;
  }
}

.video_box {
  max-height: 550px;
}

.iframe_box {
  height: 550px;
  max-height: 550px;
}

.word_box {
  max-height: 550px;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
